











import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { Variable, VariableResults } from '@/models/variable';
import store from '@/store';

@Component
export default class FilterMultiselect extends Vue {
  @Prop({ required: true }) queryResult: any | null;

  @Prop({ required: true }) variable!: Variable;

  selection: any[] = [];

  @Watch('selection', { deep: true })
  onSelectionChange(selection: any[]): void {
    console.log('selection: ', selection);
    const variable: VariableResults = {
      name: this.variable.name,
      values: this.selection,
    };
    store.dispatch('setOrUpdateVariable', variable);
  }
}
